import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { formatDistance, isSameDay, isSameYear } from "date-fns";
import React from "react";
import { DEFAULT_CREATOR_THEME } from "../../../features/Common/modules/Theme/constants/Theme.constants";
import { CreatorThemeContext } from "../../../features/Common/modules/Theme/CreatorThemeContext";
import { pluralise } from "../../../utils/common/dataTypes/string";
import { formatDateTime } from "../../../utils/common/dateTime";
import { classNames } from "../../../utils/common/styling";
import Button from "../../common/Button/Button";
import ReadMoreWrapper from "../../common/ReadMoreWrapper/ReadMoreWrapper";
import LazyLoadedLikedByMembersListModal from "../LikedByMembersListModal/LazyLoadedLikedByMembersListModal";
import LazyLoadedPostDoc from "../PostDoc/LazyLoadedPostDoc";
import LazyLoadedPostImage from "../PostImage/LazyLoadedPostImage";
import LazyLoadedPostOffering from "../PostOffering/LazyLoadedPostOffering";
import LazyLoadedPostVideo from "../PostVideo/LazyLoadedPostVideo";
import LazyLoadedCommentEditor from "./components/CommentEditor/LazyLoadedCommentEditor";
import { USER_AVATAR_PREFERENCE } from "./components/CommenterImg/constants/CommenterImg.constants";
import LazyLoadedCommentSection from "./components/CommentSection/LazyLoadedCommentSection";
import LinkPreview from "./components/LinkPreview/LinkPreview";
import MoreButtonAndMenu from "./components/MoreButtonAndMenu/MoreButtonAndMenu";
import PinnedStatusIcon from "./components/PinnedStatusIcon/PinnedStatusIcon";
import { getThemedStyles, styles } from "./Post.styles";
import { IPostProps } from "./Post.types";

const Post = ({
  // props from hook, used by Post directly
  data,
  commentCount,
  onLikeClick,
  openLikedByMembersListModal,
  lazyLoadedLikedByMembersListModalProps,
  onPinClick,
  enableComments = false,
  canEdit,
  onEditClick,
  canDelete,
  onDeleteClick,
  currentUserIsPostAuthor,
  canClickOnPostAuthor,
  onPostAuthorClick,

  // props from hook, used by components inside Post
  postOfferingProps,
  postImgProps,
  postVideoProps,
  postDocProps,
  onShowCommentInputClick,
  onShowCommentsClick,
  lazyLoadedCommentEditorProps,
  lazyLoadedCommentSectionProps,

  // props from parent component, used by Post directly
  classes = {},
  userImgSrc,
  collapsedHeight = 50,
  theme = DEFAULT_CREATOR_THEME,
  linkPreviewApiKey,
  errorLogger,
}: IPostProps) => {
  const themedStyles = getThemedStyles({ theme });
  const now = new Date();
  const createdAt = new Date(data.created_at);
  const isSameDayAsToday = isSameDay(now, createdAt);
  const isSameYearAsCurrent = isSameYear(now, createdAt);
  const createdAtString = isSameDayAsToday
    ? formatDistance(createdAt, now, { addSuffix: true })
    : formatDateTime(createdAt, {
        showTime: true,
        showYear: !isSameYearAsCurrent,
      });
  const showLikeAndCommentCountContainer = data.likes_count || commentCount;

  const { metadata } = data;
  const { preview_urls: previewUrls } = metadata ?? {};
  const [firstUrl] = previewUrls ?? []; // only showing first link preview for now

  const postTextProps = {
    id: `post-text--${data.post_uuid}`,
    className: styles.postText,
    dangerouslySetInnerHTML: { __html: data.post_body },
  };

  const { commenterDetails } = lazyLoadedCommentEditorProps;

  /**
   * Option for share needs to be added over here
   * i.e isSingleOptionAvailable = !enableSharing && !enableComments;
   */
  const isSingleOptionAvailable = !enableComments;

  return (
    <CreatorThemeContext.Provider value={theme}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.postAuthorDetails}>
            <img
              className={styles.postAuthorPic}
              alt={data.name}
              src={data.profile_pic}
              width={46}
              height={46}
            />
            <div className={styles.authorNameAndCreatedAtDateContainer}>
              <div
                className={classNames(
                  styles.authorName,
                  canClickOnPostAuthor && styles.authorNameClickable,
                )}
                onClick={onPostAuthorClick}
              >
                {data.name}
                {currentUserIsPostAuthor ? (
                  <span className={styles.authorIsCurrentUserIndicator}>
                    {" (You)"}
                  </span>
                ) : null}
              </div>
              <div className={styles.createdAtDate}>{createdAtString}</div>
            </div>
          </div>
          <div className={styles.pinAndMoreMenuBtnContainer}>
            {onPinClick ? (
              <PinnedStatusIcon
                onClick={onPinClick}
                isPinned={data.is_pinned}
              />
            ) : null}
            <MoreButtonAndMenu
              MoreButtonComponent={MoreHorizIcon}
              canEdit={canEdit}
              onEditClick={onEditClick}
              canDelete={canDelete}
              onDeleteClick={onDeleteClick}
            />
          </div>
        </div>
        <div className={styles.postTextContainer}>
          {data.is_pinned ? (
            <div {...postTextProps} />
          ) : (
            <ReadMoreWrapper
              childDivProps={postTextProps}
              collapsedHeight={collapsedHeight}
              ExpandToggleButton={({ onClick, isExpanded }) =>
                isExpanded ? null : (
                  <div className={styles.seeMoreButtonContainer}>
                    <div
                      onClick={onClick}
                      className={classNames(
                        styles.seeMoreButton,
                        classes.seeMoreButton,
                      )}
                    >
                      ...see more
                    </div>
                  </div>
                )
              }
            />
          )}
        </div>
        <LazyLoadedPostOffering {...postOfferingProps} />
        <LazyLoadedPostImage {...postImgProps} />
        <LazyLoadedPostVideo {...postVideoProps} />
        <LazyLoadedPostDoc
          {...postDocProps}
          classes={{ container: styles.postDocContainer }}
        />

        {firstUrl ? (
          <LinkPreview
            url={firstUrl}
            apiKey={linkPreviewApiKey}
            errorLogger={errorLogger}
          />
        ) : null}

        {showLikeAndCommentCountContainer ? (
          <div className={styles.likeAndCommentCountContainer}>
            {data.likes_count ? (
              <div
                className={styles.likeCountContainer}
                onClick={openLikedByMembersListModal}
              >
                <div
                  className={classNames(
                    styles.likesCountIconContainer,
                    themedStyles.likesCountIconContainer,
                    "d-flex align-items-center justify-content-center rounded-circle mr-2",
                  )}
                >
                  <FavoriteRoundedIcon
                    htmlColor="white"
                    className={styles.heartIcon}
                  />
                </div>
                <div
                  className={classNames(
                    styles.likesCount,
                    themedStyles.likesCount,
                  )}
                >
                  {data.likes_count}
                </div>
              </div>
            ) : (
              <div></div>
              // empty div, so that `justify-content: space-between` works properly
            )}
            {enableComments && commentCount ? (
              <Button
                color="unset"
                size="unset"
                className={styles.commentsCount}
                onClick={onShowCommentsClick}
              >
                {commentCount} {pluralise("comment", commentCount)}
              </Button>
            ) : (
              <div></div>
              // empty div, so that `justify-content: space-between` works properly
            )}
          </div>
        ) : null}
        <div className={styles.reactionStripContainer}>
          <div
            className={classNames(
              styles.reactionBtn,
              /**
               * justifyContent - flex-start for like button
               * in case its the only option available.
               * otherwise its center justified as others.
               **/
              isSingleOptionAvailable
                ? styles.justifyContentStart
                : styles.justifyContentCenter,
            )}
            onClick={onLikeClick}
          >
            {data.has_liked_post ? (
              <FavoriteRoundedIcon
                className={classNames(
                  styles.likedByUserIcon,
                  themedStyles.likedByUserIcon,
                )}
              />
            ) : (
              <FavoriteBorderRoundedIcon />
            )}
            Like
          </div>
          {enableComments && (
            <>
              <div className={styles.verticalSeparator} />
              <div
                className={classNames(
                  styles.reactionBtn,
                  styles.justifyContentCenter,
                )}
                onClick={onShowCommentInputClick}
              >
                <AddCommentOutlinedIcon
                  className={styles.showCommentInputIcon}
                />
                Comment
              </div>
            </>
          )}
        </div>
        {enableComments && (
          <>
            <LazyLoadedCommentEditor
              {...lazyLoadedCommentEditorProps}
              commenterDetails={{
                ...commenterDetails,
                profilePicUrl: userImgSrc,
              }}
              creatorTheme={theme}
              classes={{ container: styles.replyTextAreaContainer }}
              userAvatarPreference={USER_AVATAR_PREFERENCE.PROFILE_PICTURE}
            />
            <LazyLoadedCommentSection
              {...lazyLoadedCommentSectionProps}
              classes={{ container: styles.commentSectionContainer }}
              userAvatarPreference={USER_AVATAR_PREFERENCE.PROFILE_PICTURE}
              creatorTheme={theme}
              userImgSrc={userImgSrc}
            />
          </>
        )}
        <LazyLoadedLikedByMembersListModal
          {...lazyLoadedLikedByMembersListModalProps}
        />
      </div>
    </CreatorThemeContext.Provider>
  );
};

export default Post;
