import { useEffect, useState } from "react";
import { useToggleState } from "../../../../../../utils/common/dataTypes/boolean";
import { ILinkPreviewMetadata } from "./LinkPreview.interfaces";
import { fetchWebpageMetadata } from "./utils/fetchWebpageMetadata";
import { getUnsupportedSiteLinkPreviewData } from "../../../../../Offerings/modules/BrandedCommunity/components/LinkPreview/utils/LinkPreview.utils";
import { ErrorLogger } from "../../../../../../interfaces/logError";

const useLinkPreview = ({
  url,
  apiKey,
  errorLogger,
}: {
  url?: string;
  apiKey?: string;
  errorLogger?: ErrorLogger;
}) => {
  const [previewData, setPreviewData] = useState<ILinkPreviewMetadata | null>(
    null,
  );
  const [isLoading, startLoading, stopLoading] = useToggleState(false);

  const fetchData = async () => {
    if (!url) return;

    const unsupportedLinkData = getUnsupportedSiteLinkPreviewData(url);

    // if url belongs to an unsupported site, set preview data and return
    if (unsupportedLinkData) {
      setPreviewData(unsupportedLinkData);
      return;
    }

    startLoading();

    const handleError = (error: string) => {
      if (errorLogger)
        errorLogger({
          error,
          when: "fetching link preview",
          occuredAt:
            "src/features/Common/modules/Urls&Routing/modules/LinkPreview/useLinkPreview.ts",
          severity: "critical",
        });
    };

    fetchWebpageMetadata({
      url,
      apiKey,
      onSuccess: setPreviewData,
      onError: handleError,
      onFinally: stopLoading,
    });
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return { previewData, isLoading };
};

export default useLinkPreview;
