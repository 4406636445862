export const CONTENT_TYPE = {
  VIDEO: 1, //  (Would have video url as content)
  IMAGE: 2, //  (Would have image url as content)
  HTML: 3, //  (Would have html string as content)
  DOWNLOAD: 4, //  Item (Would have attachment download link as content)
  HYPERLINK: 5, //  Iteem (Would have link as content)
  PDF: 6,
  EMBED: 7, // Item would have iframe of SpeedExam as content
  EXAM: 8,
  FORM: 9,
  LIVE_SESSION: 10, // Item would have iframe of SpeedExam as content
  PROGRESS_TRACKING_FORM: 11,
};

export const COURSE_UNLOCKING_TYPES = {
  IMMEDIATELY: 1,
  ON_SPECIFIC_DATE: 2,
  POST_START_DAYS: 3,
  POST_PREVIOUS_COMPLETION: 4,
  POST_INSTALLMENTS: 5,
};

export const VID_WATCHED_TIMESTAMP_UPDATE_DURATION = 30;

export const CERTIFICATE_TRIGGERS_TYPE = {
  POST_COURSE_PURCHASE: 1,
  POST_COURSE_COMPLETION: 2,
  POST_ALL_EXAM_COMPLETION: 3,
  POST_SPECIFIC_EXAM_COMPLETION: 4,
};

// video hosting platforms
export const RECORDED_CONTENT_VIDEO_SOURCE = {
  WISTIA: 1,
  VDOCIPHER_NON_DRM: 2,
  VDOCIPHER_DRM: 3,
  EXTERNAL_LINK: 4,
};
